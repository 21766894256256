/* eslint-disable sort-keys */
import { useMemo } from 'react'
import { Theme } from '@material-ui/core/styles'
import { createTheme } from '@material-ui/core/styles'
import { esES } from '@material-ui/core/locale'
import { State } from 'easy-peasy'

import { themeColors } from 'common/colors'

import {
  colorBlackish,
  colorGallery,
  colorGrayDark,
  colorGrayLight,
  colorGrayMedium,
  colorHippieGreen,
  colorPrimaryDark,
  colorPrimaryLight,
  colorRiverBed,
  colorWhite,
} from 'common/colors'
import { fontSizes, fonts } from 'common/fonts'
import { calcRem } from 'utils/styles'
import { StateModel, useStoreState } from 'store'
import { SessionModel } from 'types'

import { colorSecondaryDark } from './../common/colors'

interface Type {
  theme: Theme
}

const defaultTheme = createTheme()

function responsiveFontSize(size: number): any {
  return {
    [defaultTheme.breakpoints.down('sm')]: {
      fontSize: calcRem(size * 0.8),
    },
  }
}

function useCustomPalette(): {
  badgeBackgroundColor: string
  boxShadowColor: string
  dividerColor: string
  overlayColor: string
  textColorWithMainBackground: string
  textColorWithPrimaryBackground: string
  useDarkMode: boolean
} {
  const { useDarkMode = false } = useStoreState(
    (state: StateModel): State<SessionModel> => state.session,
  )

  return {
    badgeBackgroundColor: useDarkMode ? colorPrimaryDark : colorPrimaryLight,
    /**
     * Use when the text is in front of the main (white/black) background.
     */
    textColorWithMainBackground: useDarkMode ? colorWhite : colorBlackish,

    /**
     * Use when the text is in front of a yellow background.
     */
    textColorWithPrimaryBackground: useDarkMode ? colorWhite : colorBlackish,

    boxShadowColor: useDarkMode ? colorPrimaryLight : colorHippieGreen,

    dividerColor: useDarkMode ? colorRiverBed : colorGallery,

    overlayColor: useDarkMode ? colorWhite : colorBlackish,

    useDarkMode: useDarkMode,
  }
}

function useCustomTheme(): Type {
  const {
    body1,
    body2,
    button,
    caption,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    overline,
    subtitle1,
    subtitle2,
  } = fontSizes

  const { useDarkMode = false } = useStoreState(
    (state: StateModel): State<SessionModel> => state.session,
  )

  const theme = useMemo(
    () =>
      createTheme(
        {
          palette: {
            background: {
              default: useDarkMode
                ? themeColors.palette.background.dark
                : themeColors.palette.background.light,
            },
            primary: {
              dark: themeColors.palette.primary.light.dark,
              light: themeColors.palette.primary.light.light,
              main: themeColors.palette.primary.light.main,
            },
            secondary: {
              main: useDarkMode
                ? themeColors.palette.secondary.dark.main
                : themeColors.palette.secondary.light.main,
            },
            grey: {
              600: colorGrayDark,
              500: colorGrayMedium,
              400: colorGrayLight,
            },
            text: {
              disabled: colorGrayMedium,
              primary: useDarkMode ? colorWhite : colorBlackish,
              secondary: colorGrayMedium,
            },
            common: {
              black: useDarkMode ? colorWhite : colorBlackish,
            },
            error: {
              main: themeColors.palette.error.light.main,
            },
            type: useDarkMode ? 'dark' : 'light',
          },
          spacing: 4,
          typography: {
            fontFamily: fonts.main,
          },
          props: {
            MuiButtonBase: {
              disableRipple: true,
            },
          },
          overrides: {
            MuiAccordion: {
              root: {
                '&:before': {
                  display: 'none',
                },
              },
            },
            MuiAppBar: {
              root: {
                boxShadow: 'none',
              },
            },
            MuiBackdrop: {
              root: {
                backgroundColor: 'rgba(0,0,0,0.8)',
              },
            },
            MuiButton: {
              root: {
                boxShadow: 'none',
                disableRipple: true,
                fontSize: calcRem(button),
                fontWeight: 700,
                letterSpacing: calcRem(1.25),
                lineHeight: 1.2,
                textDecoration: 'none',
              },
              contained: {
                minHeight: calcRem(48),
                // minWidth: calcRem(200),
              },
              outlined: {
                minHeight: calcRem(48),
                // minWidth: calcRem(200),
              },
            },
            MuiCardContent: {
              root: {
                paddingTop: 0,
              },
            },
            MuiCheckbox: {
              root: {
                color: colorGrayMedium,
                paddingBottom: 0,
                paddingTop: calcRem(2),
              },
            },
            MuiDivider: {
              middle: {
                marginLeft: 0,
                marginRight: calcRem(16),
              },
            },
            MuiExpansionPanel: {
              root: {
                '&:before': {
                  display: 'none',
                },
              },
            },
            MuiFilledInput: {
              root: {
                '&:focus, &:hover': {
                  backgroundColor: colorWhite,
                },
                backgroundColor: colorWhite,
                border: '1px solid rgba(0, 0, 0, .32)',
                borderRadius: calcRem(4),
              },
              input: {
                '&::placeholder': {
                  color: colorGrayMedium,
                  opacity: 1,
                },
              },
            },
            MuiFormControl: {
              root: {
                '&.textFieldDirty': {
                  '& fieldset': {
                    borderColor: useDarkMode ? colorWhite : colorBlackish,
                  },
                },
                '& .MuiFormLabel-filled + div': {
                  '& fieldset': {
                    borderColor: useDarkMode ? colorWhite : colorBlackish,
                  },
                },
              },
            },
            MuiFormControlLabel: {
              root: {
                alignItems: 'flex-start',
                paddingBottom: calcRem(9),
              },
            },
            MuiFormHelperText: {
              root: {
                '&.Mui-error': {
                  whiteSpace: 'nowrap',
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                },

                bottom: calcRem(-22),
                color: useDarkMode ? colorWhite : colorBlackish,
                lineHeight: 1.34,
                position: 'absolute',
              },
            },
            MuiInputAdornment: {
              positionEnd: {
                '& .MuiIconButton-root': {
                  padding: 0,
                },
              },
            },
            MuiInputBase: {
              adornedEnd: {
                '& .MuiIconButton-root': {
                  padding: 0,
                },
              },
              input: {
                '&::placeholder': {
                  color: colorGrayMedium,
                  opacity: 1,
                },
              },
            },
            MuiInputLabel: {
              root: {
                color: colorGrayMedium,
                // fontSize: calcRem(body1 * 0.9),
                // width: 'calc(100% - 14px)',
              },
              // outlined: {
              // '&.MuiInputLabel-shrink': {
              //   transform: 'translate(14px, -6px) scale(0.8)',
              // },
              // },
            },
            MuiListItem: {
              root: {
                '&.Mui-selected:hover': {
                  backgroundColor: colorPrimaryLight,
                },
              },
              button: {
                '&:hover': {
                  backgroundColor: colorPrimaryLight,
                  color: useDarkMode ? colorWhite : colorBlackish,
                },
                '&.Mui-selected': {
                  backgroundColor: colorPrimaryLight,
                  color: useDarkMode ? colorWhite : colorBlackish,
                },
              },
            },
            MuiPaper: {
              root: {
                backgroundColor: useDarkMode ? colorBlackish : colorWhite,
              },
            },
            MuiSelect: {
              select: {
                textTransform: 'capitalize',
              },
            },
            MuiTab: {
              root: {
                fontSize: calcRem(12),
                fontWeight: 'bold',
                letterSpacing: calcRem(1.2),
              },
            },
            MuiTabs: {
              root: {
                backgroundColor: useDarkMode ? colorBlackish : colorWhite,
              },
            },
            MuiTableCell: {
              head: {
                fontWeight: 'bold',
                padding: `${calcRem(16)} ${calcRem(8)}`,
              },
              root: {
                padding: calcRem(8),

                [defaultTheme.breakpoints.down('sm')]: {
                  padding: `${calcRem(4)} ${calcRem(8)}`,
                },

                borderBottom: `1px solid ${themeColors.table.divider}`,
                fontWeight: 500,
              },
            },
            // @ts-ignore
            MuiToggleButton: {
              root: {
                '&.Mui-selected': {
                  color: 'white',
                  backgroundColor: colorSecondaryDark,
                },
              },
              sizeLarge: {
                width: '100%',
              },
            },
            MuiToggleButtonGroup: {
              root: {
                width: '100%',
              },
            },
            MuiToolbar: {
              root: {
                backgroundColor: useDarkMode ? colorBlackish : colorWhite,
              },
            },
            MuiTypography: {
              root: {
                willChange: 'transform',
              },
              gutterBottom: {
                marginBottom: calcRem(16),
              },
              h1: {
                fontSize: calcRem(h1),
                fontWeight: 700,
                fontFamily: fonts.title,
                letterSpacing: 0,
                lineHeight: 1.2,
                ...responsiveFontSize(h1),
              },
              h2: {
                fontSize: calcRem(h2),
                fontWeight: 700,
                fontFamily: fonts.title,
                letterSpacing: calcRem(0.25),
                ...responsiveFontSize(h2),
              },
              h3: {
                fontSize: calcRem(h3),
                fontWeight: 700,
                letterSpacing: 0,
                lineHeight: 1.17,
                fontFamily: fonts.title,
                ...responsiveFontSize(h3),
              },
              h4: {
                fontSize: calcRem(h4),
                fontWeight: 700,
                letterSpacing: calcRem(0.15),
                lineHeight: 1.2,
                fontFamily: fonts.title,
                ...responsiveFontSize(h4),
              },
              h5: {
                fontSize: calcRem(h5),
                fontWeight: 700,
                fontFamily: fonts.title,
                ...responsiveFontSize(h5),
              },
              h6: {
                fontSize: calcRem(h6),
                fontWeight: 700,
                fontFamily: fonts.title,
                ...responsiveFontSize(h6),
              },
              subtitle1: {
                fontSize: calcRem(subtitle1),
                fontWeight: 700,
                letterSpacing: calcRem(0.15),
                ...responsiveFontSize(subtitle1),
              },
              subtitle2: {
                fontSize: calcRem(subtitle2),
                fontWeight: 700,
                letterSpacing: calcRem(0.1),
                ...responsiveFontSize(subtitle2),
              },
              body1: {
                fontSize: calcRem(body1),
                fontWeight: 'normal',
                letterSpacing: calcRem(0.5),
                lineHeight: 1.8,
                ...responsiveFontSize(body1),
              },
              body2: {
                fontSize: calcRem(body2),
                fontWeight: 'normal',
                letterSpacing: calcRem(0.25),
                ...responsiveFontSize(body2),
              },
              button: {
                boxShadow: 'none',
                fontSize: calcRem(button),
                fontWeight: 700,
                letterSpacing: calcRem(1.25),
                textDecoration: 'none',
                ...responsiveFontSize(button),
              },
              caption: {
                fontSize: calcRem(caption),
                fontWeight: 'normal',
                letterSpacing: calcRem(0.4),
                lineHeight: calcRem(16),
                ...responsiveFontSize(caption),
              },
              overline: {
                fontSize: calcRem(overline),
                fontWeight: 700,
                letterSpacing: calcRem(1.5),
                lineHeight: calcRem(16),
                ...responsiveFontSize(overline),
              },
            },
          },
        },
        esES,
      ),
    [
      useDarkMode,
      body1,
      body2,
      button,
      caption,
      h1,
      h2,
      h3,
      h4,
      h5,
      h6,
      overline,
      subtitle1,
      subtitle2,
    ],
  )

  return {
    theme,
  }
}

export { useCustomTheme, useCustomPalette }
